import {
    GeneralInformationTabRenderer,
    ClassificationsTabRenderer,
    CountriesTabRenderer,
    LimitsTabRenderer,
    PremiumsTabRenderer,
    DeductionsTabRenderer,
    ShareTabRenderer,
    ClaimsTabRenderer,
    PortfolioConditionsTabRenderer,
    AdminConditionsTabRenderer,
    OtherConditionsTabRenderer,
    InstalmentsTabRenderer,
    AdjustmentsTabRenderer,
    ReinstatementsTabRenderer
} from '../../tab_renderers';
import { TabProps } from '../../componentTypes';
import { TabIds } from '../../misc';

type Registry = Record<string, React.FC<TabProps> | undefined>;

const TabRendererRegistry: Registry = {
    [TabIds.GeneralInformation]: GeneralInformationTabRenderer,
    [TabIds.Classifications]: ClassificationsTabRenderer,
    [TabIds.Countries]: CountriesTabRenderer,
    [TabIds.Limits]: LimitsTabRenderer,
    [TabIds.Premiums]: PremiumsTabRenderer,
    [TabIds.Deductions]: DeductionsTabRenderer,
    [TabIds.Share]: ShareTabRenderer,
    [TabIds.Claims]: ClaimsTabRenderer,
    [TabIds.PortfolioConditions]: PortfolioConditionsTabRenderer,
    [TabIds.AdminConditions]: AdminConditionsTabRenderer,
    [TabIds.OtherConditions]: OtherConditionsTabRenderer,
    [TabIds.Instalments]: InstalmentsTabRenderer,
    [TabIds.Adjustments]: AdjustmentsTabRenderer,
    [TabIds.Reinstatements]: ReinstatementsTabRenderer
};

export default TabRendererRegistry;

import TabIds from './TabIds';

const InputIds = {
    [TabIds.GeneralInformation]: {
        Title: 'Title',
        MainClass: 'MainClass',
        Class: 'Class',
        SubClass: 'SubClass',
        TypeOfParticipation: 'TypeOfParticipation',
        Lifecycle: 'Lifecycle',
        UnderwritingYear: 'UnderwritingYear',
        PeriodStart: 'PeriodStart',
        PeriodEnd: 'PeriodEnd',
        Currency: 'Currency',
        Countries: 'Countries',
        CountryGroups: 'CountryGroups',
        Cedent: 'Cedent',
        Reinsurer: 'Reinsurer',
        CreateProgram: 'CreateProgram',
        ReportUnits: 'ReportUnits',
        BusinessType: 'BusinessType',
        Insured: 'Insured',
        Broker: 'Broker',
        BusinessId: 'BusinessId',
        ExpiringPeriodStart: 'ExpiringPeriodStart'
    } as const,
    [TabIds.Classifications]: {
        MainClass: 'MainClass',
        Class: 'Class',
        SubClass: 'SubClass'
    } as const,
    [TabIds.Countries]: {
        Countries: 'Countries',
        CountryGroups: 'CountryGroups'
    } as const,
    [TabIds.Limits]: {
        Cover: 'Cover',
        Excess: 'Excess',
        Liability: 'Liability',
        MainLimit: 'MainLimit',
        TotalSumInsured: 'TotalSumInsured',
        LimitType: 'LimitType',
        LimitValue: 'LimitValue'
    } as const,
    [TabIds.Premiums]: {
        EstimatedPremiumIncome: 'EstimatedPremiumIncome',
        MinimalPremium: 'MinimalPremium',
        DepositPremium: 'DepositPremium',
        SubjectPremiumIncome: 'SubjectPremiumIncome',
        PremiumRate: 'PremiumRate',
        PremiumType: 'PremiumType',
        TotalGrossPremium: 'TotalGrossPremium'
    } as const,
    [TabIds.Deductions]: {
        Commission: 'Commission',
        Brokerage: 'Brokerage',
        TaxOnPremium: 'TaxOnPremium',
        DeductionType: 'DeductionType',
        DeductionValue: 'DeductionValue'
    } as const,
    [TabIds.Share]: {
        ShareOffer: 'ShareOffer',
        ShareWritten: 'ShareWritten',
        ShareSigned: 'ShareSigned',
        CessionPercent: 'CessionPercent'
    } as const,
    [TabIds.Claims]: {
        ClaimBasis: 'ClaimBasis',
        ClaimAdviceLimit: 'ClaimAdviceLimit'
    } as const,
    [TabIds.PortfolioConditions]: {
        PremiumEntryCalculationMethod: 'PremiumEntryCalculationMethod',
        PremiumEntryPercent: 'PremiumEntryPercent',
        PremiumEntryPercentOf: 'PremiumEntryPercentOf',
        PremiumWithdrawalCalculationMethod: 'PremiumWithdrawalCalculationMethod',
        PremiumWithdrawalPercent: 'PremiumWithdrawalPercent',
        PremiumWithdrawalPercentOf: 'PremiumWithdrawalPercentOf',
        LossEntryCalculationMethod: 'LossEntryCalculationMethod',
        LossEntryPercent: 'LossEntryPercent',
        LossWithdrawalCalculationMethod: 'LossWithdrawalCalculationMethod',
        LossWithdrawalPercent: 'LossWithdrawalPercent'
    } as const,
    [TabIds.AdminConditions]: {
        CessionBasis: 'CessionBasis',
        CashClaimLimit: 'CashClaimLimit'
    } as const,
    [TabIds.OtherConditions]: {
        CancellationTerms: 'CancellationTerms',
        NOCPeriod: 'NOCPeriod',
        PremiumPaymentWarranty: 'PremiumPaymentWarranty',
        PPWPeriod: 'PPWPeriod',
        InclusionClauses: 'InclusionClauses',
        ExclusionClauses: 'ExclusionClauses'
    } as const,
    [TabIds.Instalments]: {
        InstalmentsCount: 'InstalmentsCount',
        InstalmentType: 'InstalmentType',
        FirstInstalmentCount: 'FirstInstalmentCount',
        FirstInstalmentPeriod: 'FirstInstalmentPeriod',
        SubsequentInstalmentCount: 'SubsequentInstalmentCount',
        SubsequentInstalmentPeriod: 'SubsequentInstalmentPeriod'
    } as const,
    [TabIds.Adjustments]: {
        AdjustmentsCount: 'AdjustmentsCount',
        FirstPeriodCount: 'FirstPeriodCount',
        FirstPeriodTime: 'FirstPeriodTime',
        SubsequentAdjustmentsPeriodCount: 'SubsequentAdjustmentsPeriodCount',
        SubsequentAdjustmentsPeriodTime: 'SubsequentAdjustmentsPeriodTime',
        SubmissionWithinCount: 'SubmissionWithinCount',
        SubmissionWithinPeriod: 'SubmissionWithinPeriod',
        PaymentWithinCount: 'PaymentWithinCount',
        PaymentWithinPeriod: 'PaymentWithinPeriod'
    } as const,
    [TabIds.Reinstatements]: {
        CurrencyType: 'CurrencyType',
        CalculationRule: 'CalculationRule',
        ReinstatementsType: 'ReinstatementsType',
        CountOfReinstatements: 'CountOfReinstatements',
        PaymentRule: 'PaymentRule',
        AmountPercent: 'AmountPercent',
        TimePercent: 'TimePercent',
        BrokerageType: 'BrokerageType',
        BrokeragePercent: 'BrokeragePercent'
    } as const
} as const;

export default InputIds;

import * as React from 'react';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { MenuUnfoldIcon } from '../../../custom_shared/icons';
import { ClickableIcon } from '../../../custom_shared/components';
import './MinimapToggleButton.less';

const MinimapToggleButton: React.FC = () => {
    const { documentPreviewStore } = useAppStore();

    return (
        <Tooltip title={`${documentPreviewStore.showMinimap ? 'Close' : 'Open'} minimap`} placement="topLeft">
            <div className="minimap-toggle-button-container">
                <ClickableIcon
                    width={20}
                    height={18}
                    icon={MenuUnfoldIcon}
                    onClick={() => documentPreviewStore.setShowMinimap(!documentPreviewStore.showMinimap)}
                />
            </div>
        </Tooltip>
    );
};

export default observer(MinimapToggleButton);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    CurrencyTypeInput,
    CalculationRuleInput,
    ReinstatementsTypeInput,
    CountOfReinstatementsInput,
    PaymentRuleInput,
    AmountPercentInput,
    TimePercentInput,
    BrokerageTypeInput,
    BrokeragePercentInput
} from '../../tab_inputs/ReinstatementsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils, InputIds } from '../../misc';
import { useTabForm } from '../../hooks';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.Reinstatements.CurrencyType]: CurrencyTypeInput,
    [InputIds.Reinstatements.CalculationRule]: CalculationRuleInput,
    [InputIds.Reinstatements.ReinstatementsType]: ReinstatementsTypeInput,
    [InputIds.Reinstatements.CountOfReinstatements]: CountOfReinstatementsInput,
    [InputIds.Reinstatements.PaymentRule]: PaymentRuleInput,
    [InputIds.Reinstatements.AmountPercent]: AmountPercentInput,
    [InputIds.Reinstatements.TimePercent]: TimePercentInput,
    [InputIds.Reinstatements.BrokerageType]: BrokerageTypeInput,
    [InputIds.Reinstatements.BrokeragePercent]: BrokeragePercentInput
} as const;

const ReinstatementsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 3).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(ReinstatementsTabRenderer);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from 'antd';
import { useAppStore } from '../../stores';
import TabTitle from './TabTitle';
import './TabsNavigation.less';

const TabsNavigation: React.FC = () => {
    const { tabStore } = useAppStore();

    return (
        <div className="contract-ingestion-tabs-navigation-container">
            <Tabs
                className="contract-ingestion-tabs-navigation"
                activeKey={tabStore.activeTabId}
                onChange={tabStore.setActiveTabId}
            >
                {tabStore.tabs.map(tab => (
                    <Tabs.TabPane tab={<TabTitle tab={tab} />} key={tab.id} forceRender />
                ))}
            </Tabs>
        </div>
    );
};

export default observer(TabsNavigation);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { WarningIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';
import './ErrorsRenderer.less';

const ErrorsRenderer: React.FC = () => {
    const { sessionStore } = useAppStore();

    if (sessionStore.contractSending || !sessionStore.errors.length) {
        return null;
    }

    return (
        <div className="errors-renderer-container">
            <div className="errors-renderer-content">
                <div className="errors-renderer-left-column">
                    <WarningIcon />
                </div>

                <div className="errors-renderer-right-column">
                    {sessionStore.errors.map((error, index) => (
                        <span key={`${error.errorCode}-${index}`} className="errors-renderer-label">
                            {error.message}: {error.details}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default observer(ErrorsRenderer);

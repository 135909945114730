import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { DocumentVisualStore } from '../stores';
import { Button } from 'antd';
import * as _ from 'lodash';
import { PageNumberInput } from '.';

type Props = {
    documentVisualStore: DocumentVisualStore;
    paginationPosition: string;
    hasLeftContainer?: boolean;
    usePageNumberInput?: boolean;
    packageId?: string;
    sessionId?: string;
    defaultScale?: number;
    minScale?: number;
    maxScale?: number;
    targetWindow: Window;
};

export const DocumentPagination: React.FC<Props> = ({
    documentVisualStore,
    paginationPosition,
    hasLeftContainer = true,
    usePageNumberInput,
    packageId,
    sessionId,
    defaultScale = 1,
    minScale = 0.5,
    maxScale = 5,
    targetWindow
}) => {
    const changeScale = (scale: number) => {
        documentVisualStore.setScale(_.clamp(+scale.toFixed(2), minScale, maxScale));
    };

    const getLeftPosition = () => {
        if (hasLeftContainer) {
            let container = targetWindow.document.getElementsByClassName('leftColumnContainer')[0];

            if (container && container.clientWidth > 530) {
                return 'calc(50% + 245px)';
            } else {
                return 'calc(50% + 125px)';
            }
        }

        return 'calc(50% - 125px)';
    };

    const pageInfo = () => {
        return (
            <Observer>
                {() => (
                    <span className="page-info">
                        Page {documentVisualStore.currentPage + 1} / {documentVisualStore.totalPages}
                    </span>
                )}
            </Observer>
        );
    };

    return (
        <div
            className="alpha-pagination-container"
            style={{
                position: 'absolute',
                left: getLeftPosition(),
                width: 'auto',
                textAlign: 'left',
                bottom: paginationPosition,
                zIndex: 1000
            }}
        >
            <div className="alpha-pagination">
                <div className="alpha-pagination-left-container" style={{ textAlign: 'left' }}>
                    {usePageNumberInput ? (
                        <PageNumberInput
                            documentVisualStore={documentVisualStore}
                            packageId={packageId}
                            sessionId={sessionId}
                            targetWindow={targetWindow}
                        />
                    ) : (
                        pageInfo()
                    )}
                </div>
                <div
                    className="alpha-pagination-right-container"
                    style={{ textAlign: 'right', height: '16px', marginLeft: '21px' }}
                >
                    <Observer>
                        {() => (
                            <>
                                <Button
                                    size="small"
                                    onClick={() => changeScale(documentVisualStore.scale - 0.3)}
                                    type="link"
                                    disabled={documentVisualStore.scale <= minScale}
                                >
                                    <i
                                        className={`alpha-icon sm zoom-out ${documentVisualStore.scale <= minScale ? 'inactive' : ''}`}
                                    />
                                </Button>
                                <Button size="small" onClick={() => changeScale(defaultScale)} type="link">
                                    <i className="alpha-icon sm reset-scale" />
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => changeScale(documentVisualStore.scale + 0.3)}
                                    type="link"
                                    disabled={documentVisualStore.scale >= maxScale}
                                >
                                    <i
                                        className={`alpha-icon sm zoom-in ${documentVisualStore.scale >= maxScale ? 'inactive' : ''}`}
                                    />
                                </Button>
                            </>
                        )}
                    </Observer>
                </div>
            </div>
        </div>
    );
};

export default observer(DocumentPagination);

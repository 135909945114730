import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import TextSearch from './TextSearch';
import FullScreenButton from './FullScreenButton';
import MinimapToggleButton from './MinimapToggleButton';
import './SideDocumentPreviewHeader.less';

const SideDocumentPreviewHeader: React.FC = () => {
    const { documentPreviewStore } = useAppStore();

    return (
        <div className="side-document-preview-header">
            {!documentPreviewStore.searchInputVisible && (
                <div className="left-container">
                    <span className="preview-label">Preview</span>
                </div>
            )}

            <div className="right-container">
                <TextSearch variant="outlined" />
                <FullScreenButton />
                <MinimapToggleButton />
            </div>
        </div>
    );
};

export default observer(SideDocumentPreviewHeader);

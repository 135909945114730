/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const MenuUnfoldIcon: React.FC<IconProps> = ({ width = 15, height = 12, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M2.33203 11.6699H12.4688C13.6992 11.6699 14.3086 11.0605 14.3086 9.85352V2.69922C14.3086 1.49219 13.6992 0.882812 12.4688 0.882812H2.33203C1.10742 0.882812 0.492188 1.48633 0.492188 2.69922V9.85352C0.492188 11.0664 1.10742 11.6699 2.33203 11.6699ZM2.34375 10.7266C1.75781 10.7266 1.43555 10.416 1.43555 9.80664V2.74609C1.43555 2.13672 1.75781 1.82617 2.34375 1.82617H4.95703V10.7266H2.34375ZM12.457 1.82617C13.0371 1.82617 13.3652 2.13672 13.3652 2.74609V9.80664C13.3652 10.416 13.0371 10.7266 12.457 10.7266H5.87695V1.82617H12.457ZM3.82617 4C4.00781 4 4.16016 3.8418 4.16016 3.67188C4.16016 3.49609 4.00781 3.34375 3.82617 3.34375H2.57227C2.39648 3.34375 2.23828 3.49609 2.23828 3.67188C2.23828 3.8418 2.39648 4 2.57227 4H3.82617ZM3.82617 5.51758C4.00781 5.51758 4.16016 5.35938 4.16016 5.18359C4.16016 5.00781 4.00781 4.86133 3.82617 4.86133H2.57227C2.39648 4.86133 2.23828 5.00781 2.23828 5.18359C2.23828 5.35938 2.39648 5.51758 2.57227 5.51758H3.82617ZM3.82617 7.0293C4.00781 7.0293 4.16016 6.88281 4.16016 6.70703C4.16016 6.53125 4.00781 6.37891 3.82617 6.37891H2.57227C2.39648 6.37891 2.23828 6.53125 2.23828 6.70703C2.23828 6.88281 2.39648 7.0293 2.57227 7.0293H3.82617Z"
            fill={color}
        />
    </svg>
);

export default MenuUnfoldIcon;

/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const CrossIcon: React.FC<IconProps> = ({ width = 18, height = 18, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.65162 12.9002C4.4524 13.0994 4.44654 13.4392 4.65162 13.6384C4.8567 13.8377 5.19654 13.8377 5.39576 13.6384L9.14576 9.88844L12.8958 13.6384C13.095 13.8377 13.4407 13.8435 13.6399 13.6384C13.8391 13.4334 13.8391 13.0994 13.6399 12.9002L9.8899 9.14429L13.6399 5.39429C13.8391 5.19508 13.845 4.85523 13.6399 4.65601C13.4348 4.45094 13.095 4.45094 12.8958 4.65601L9.14576 8.40601L5.39576 4.65601C5.19654 4.45094 4.85084 4.44508 4.65162 4.65601C4.4524 4.86109 4.4524 5.19508 4.65162 5.39429L8.40162 9.14429L4.65162 12.9002Z"
            fill={color}
        />
    </svg>
);

export default CrossIcon;

import * as React from 'react';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { FullScreenIcon } from '../../../custom_shared/icons';
import { ClickableIcon } from '../../../custom_shared/components';
import './FullScreenButton.less';

const FullScreenButton: React.FC = () => {
    const { documentPreviewStore } = useAppStore();

    return (
        <Tooltip title="Open in full screen" placement="topLeft">
            <ClickableIcon
                width={24}
                height={24}
                icon={FullScreenIcon}
                onClick={() => documentPreviewStore.setPreviewPosition('fullScreen')}
            />
        </Tooltip>
    );
};

export default observer(FullScreenButton);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { ProjectsStore } from '../../common/stores';
import { Minimap } from '../../common/components';
import { ImagePreviewSize } from '../types/types';
import { DocumentVisualStore } from '../stores';
import DocumentPagination from './DocumentPagination';
import PdfImageViewer from './PdfImageViewer';
import { Spin } from 'antd';

export type Props = {
    projectsStore: ProjectsStore;
    documentVisualStore: DocumentVisualStore;
    packageId?: string;
    sessionId?: string;
    modelIsLoading?: boolean;
    paginationPosition?: string;
    hasBottomContainer?: boolean;
    hasLeftContainer?: boolean;
    defaultScale?: number;
    minScale?: number;
    maxScale?: number;
    usePageNumberInput?: boolean;
    newPageNumberOffset?: number;
    targetWindow?: Window;
    showMinimap?: boolean;
    fieldSearchMode?: boolean;
    minimapWidth?: number;
    highlightBlockCallback?: (inputId: string | undefined) => void;
};

type MinimapChildProps = {
    width: number;
    height: number;
    top: number;
    left: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node: any;
};

export const DocumentWithMinimap: React.FC<Props> = ({
    projectsStore,
    documentVisualStore,
    packageId,
    sessionId,
    modelIsLoading,
    paginationPosition,
    hasLeftContainer = true,
    hasBottomContainer = false,
    defaultScale,
    minScale,
    maxScale,
    usePageNumberInput,
    newPageNumberOffset = 0,
    targetWindow = window,
    showMinimap = true,
    fieldSearchMode,
    minimapWidth = 140,
    highlightBlockCallback
}) => {
    modelIsLoading = modelIsLoading && !documentVisualStore.isRendered;
    const sumOfPageSideMargins = 330;
    const minimapOffsetWithBottomContainer = 590;
    const minimapOffsetWithoutBottomContainer = 120;
    let minimapId = sessionId ? `${sessionId}-${packageId}-map` : new Date().getUTCMilliseconds() + '-map';
    const [pageSize, setPageSize] = React.useState(`calc(100% - ${sumOfPageSideMargins})`);
    const [minimapHeight, setMinimapHeight] = React.useState(documentVisualStore.calculateMinimapHeight());

    const repositionViewport = (event: MouseEvent) => {
        let minimapContainerChild = targetWindow.document.getElementById(minimapId);
        if (minimapContainerChild) {
            let container = minimapContainerChild.parentElement!.parentElement!;
            let minimapDiv = container.children[0] as HTMLElement;

            const viewportHighlightHeight = minimapDiv.children[0].getBoundingClientRect().height;
            const position =
                minimapDiv.scrollTop +
                event.clientY -
                minimapDiv.getBoundingClientRect().top -
                viewportHighlightHeight / 2;
            const relativePosition = position / minimapDiv.scrollHeight;
            const scrollPosition = relativePosition * container.scrollHeight;
            container.scrollTop = scrollPosition;
        }
    };

    const boundRepositionHandler = repositionViewport.bind(this);

    const handleMinimapMouseRelease = () => {
        let minimapContainerChild = targetWindow.document.getElementById(minimapId);
        if (minimapContainerChild) {
            let container = minimapContainerChild.parentElement!.parentElement!;
            let minimapDiv = container.children[0] as HTMLElement;
            minimapDiv.removeEventListener('mousemove', boundRepositionHandler);
        }
    };

    const boundMouseUpHandler = handleMinimapMouseRelease.bind(this);

    const handleMinimapClick = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        let minimapContainerChild = targetWindow.document.getElementById(minimapId);
        if (minimapContainerChild) {
            let container = minimapContainerChild.parentElement!.parentElement!;
            let minimapDiv = container.children[0] as HTMLElement;
            minimapDiv.addEventListener('mousemove', boundRepositionHandler);
            targetWindow.addEventListener('mouseup', boundMouseUpHandler);
        }
    };

    const boundClickHandler = handleMinimapClick.bind(this);

    const getCurrentPageNumber = React.useCallback(() => {
        let minimapContainerChild = targetWindow.document.getElementById(minimapId);
        if (minimapContainerChild) {
            let newPageNumber = 0;
            let container = minimapContainerChild.parentElement!.parentElement!;

            if (container.scrollTop === 0) {
                return 0;
            }

            const alphaDocContent = container.getElementsByClassName('alpha-doc-content')[0].children[0];
            const childrenArray = Array.from(alphaDocContent.children);
            const heightDiffernece = hasLeftContainer === false ? 130 : targetWindow.innerHeight / 2;
            for (var child of childrenArray) {
                const id = child.getAttribute('id');
                let index = 0;
                if (id) {
                    index = Number(id.replace(`${sessionId}-${packageId}-`, ''));
                }

                const childRect = child.getBoundingClientRect();
                if (childRect.bottom > targetWindow.innerHeight - (heightDiffernece - newPageNumberOffset)) {
                    newPageNumber = index;
                    break;
                }
            }
            return newPageNumber;
        }
        return 0;
    }, [hasLeftContainer, minimapId, packageId, sessionId, newPageNumberOffset, targetWindow]);

    const calcPageWidth = React.useCallback(() => {
        const page = targetWindow.document.getElementById(`${sessionId}-${packageId}-0`);
        const tabContent = targetWindow.document.getElementById(`tab-content-${sessionId}`);
        // TODO: refactor to react refs
        const pageContentSelectors =
            '.rightColumnContainer, .ask-alpha-right-column, .contract-ingestion-document-preview-container';
        const pageContent = tabContent ? tabContent.querySelectorAll(pageContentSelectors) : undefined;
        const pageContainer = pageContent?.length
            ? pageContent[0].getElementsByClassName('alpha-doc-content')
            : undefined;
        if (page || (pageContainer && pageContainer.length)) {
            const width = page ? page.clientWidth : pageContainer![0].clientWidth;
            documentVisualStore.setPageWidth(width > 0 ? width : documentVisualStore.pageWidth);
            const newMinimapHeight = documentVisualStore.calculateMinimapHeight();
            setMinimapHeight(newMinimapHeight > 0 ? newMinimapHeight : minimapHeight);
            if (documentVisualStore.ui) {
                documentVisualStore.prepareAllBlocks(documentVisualStore.ui, packageId);
            }

            if (documentVisualStore.highlightedField && documentVisualStore.highlightedInputId) {
                documentVisualStore.highlightBlock(
                    documentVisualStore.highlightedField,
                    documentVisualStore.highlightedInputId
                );
            }
        }
    }, [documentVisualStore, minimapHeight, packageId, sessionId, targetWindow]);

    const handleResize = React.useCallback(
        (minimapDiv: HTMLDivElement) => {
            const container = targetWindow.document.getElementsByClassName('bottomContainer')[0] as HTMLDivElement;
            let maxHeight = targetWindow.innerHeight - 80;
            if (container) {
                maxHeight = maxHeight - container.offsetHeight - 93;
            }

            maxHeight = hasLeftContainer === false ? maxHeight + 60 : maxHeight;

            minimapDiv.style.maxHeight = `${maxHeight}px`;
            minimapDiv.style.top = hasLeftContainer ? '10px' : `${targetWindow.innerHeight / 2}px`;
            calcPageWidth();
        },
        [calcPageWidth, hasLeftContainer, targetWindow]
    );

    const handleMinimapContanerScroll = React.useCallback(
        (container: HTMLDivElement, minimapDiv: HTMLDivElement) => {
            let newPageNumber = getCurrentPageNumber();

            if (newPageNumber !== documentVisualStore.currentPage) {
                newPageNumber =
                    newPageNumber < documentVisualStore.totalPages ? newPageNumber : documentVisualStore.totalPages - 1;
                documentVisualStore.setCurrentPage(newPageNumber, false);
                const pageItem = minimapDiv.getElementsByClassName('minimap-page')[newPageNumber];
                const visibility = isVisible(pageItem, minimapDiv);
                if (visibility.visible === false) {
                    pageItem?.scrollIntoView({ block: visibility.mode === 'top' ? 'start' : 'end' });
                }
            }
        },
        [documentVisualStore, getCurrentPageNumber]
    );

    const isVisible = (ele: Element, container: HTMLDivElement) => {
        const { bottom, height, top } = ele.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const mode = top <= containerRect.top ? 'top' : 'bottom';
        const pageBuffer = 250;
        const visible =
            top <= containerRect.top
                ? containerRect.top - top <= height - pageBuffer
                : bottom - containerRect.bottom <= height - pageBuffer;
        return { mode, visible };
    };

    const getSearchFields = (page: number) => {
        const documentBlocks = documentVisualStore.documentBlocks.filter(block => block.blockProps.page === page);
        return documentBlocks.map(block => documentVisualStore.getHighlightedBlockProps(block.fieldData));
    };

    const renderChild = (props: MinimapChildProps) => {
        let { width, height, left, top, node } = props;
        const container = node as HTMLDivElement;
        const div = container.children[0];
        const fallbackHeight = 138;
        const fallbackWidth = 107;
        const fallbackCoord = 0;

        if (height < width) {
            const temp = width;
            height = width;
            width = temp;
        }
        let index = Number(div.getAttribute('data-id'));
        let preview = documentVisualStore.filteredPreviews.find(p => p.page === index);
        let { highlightedBlockProps } = documentVisualStore;
        let previewHeight =
            preview && preview.imgSize
                ? documentVisualStore.calculatePreviewHeight(preview.imgSize.width, width, preview.imgSize.height)
                : height;
        const searchFields = fieldSearchMode ? getSearchFields(index) : [];
        return (
            <div
                className="minimap-page"
                style={{
                    position: 'absolute',
                    width: width && !Number.isNaN(width) ? width : fallbackWidth,
                    height: height || fallbackHeight,
                    left: left || fallbackCoord,
                    top: top || fallbackCoord
                }}
            >
                {preview ? (
                    <img
                        style={{
                            boxShadow: '0px 0px 4px 1px #dcdcdc'
                        }}
                        src={preview.url}
                        height={previewHeight || fallbackHeight}
                        width={width || fallbackWidth}
                    />
                ) : (
                    <div
                        style={{
                            border: '1px solid lightgray',
                            height: height || fallbackHeight,
                            width: width && Number(width) ? width : fallbackWidth
                        }}
                    />
                )}

                {fieldSearchMode &&
                    searchFields.map((field, i) => (
                        <div
                            key={`${index}_${i}`}
                            style={{
                                background: '#fadb14',
                                zIndex: 1000,
                                bottom: field.bottom + '%',
                                left: field.left + '%',
                                width: field.width + '%',
                                height: field.height + '%',
                                position: field.position,
                                visibility: field.visibility
                            }}
                        />
                    ))}

                {!fieldSearchMode && highlightedBlockProps && index === highlightedBlockProps.page && (
                    <div
                        style={{
                            background: '#33f14a52',
                            zIndex: 1000,
                            bottom: highlightedBlockProps.bottom + '%',
                            left: highlightedBlockProps.left + '%',
                            width: highlightedBlockProps.width + '%',
                            height: highlightedBlockProps.height + '%',
                            position: highlightedBlockProps.position,
                            visibility: highlightedBlockProps.visibility
                        }}
                    />
                )}
            </div>
        );
    };

    const updatePage = React.useCallback(() => {
        let minimapContainerChild = targetWindow.document.getElementById(minimapId);
        if (minimapContainerChild) {
            let container = minimapContainerChild.parentElement!.parentElement!;

            if (!container) {
                return;
            }

            let newPageNumber = getCurrentPageNumber();
            documentVisualStore.setCurrentPage(newPageNumber, false);
        }
    }, [documentVisualStore, getCurrentPageNumber, minimapId, targetWindow]);

    React.useEffect(() => {
        let minimapContainerChild = targetWindow.document.getElementById(minimapId);
        if (minimapContainerChild) {
            let container = minimapContainerChild.parentElement!.parentElement!;
            let minimap = container.children[0];
            const handleResizeListener = function () {
                handleResize(minimap as HTMLDivElement);
            };
            if (container && minimap) {
                let minimapDiv = minimap as HTMLDivElement;
                let offsetHeight =
                    hasBottomContainer && hasLeftContainer
                        ? minimapOffsetWithBottomContainer
                        : minimapOffsetWithoutBottomContainer;
                minimapDiv.style.maxHeight = `${targetWindow.innerHeight - offsetHeight}px`;
                minimapDiv.setAttribute('data-id', `minimap-${sessionId}`);
                targetWindow.addEventListener('resize', handleResizeListener);
                minimapDiv.addEventListener('mousedown', boundClickHandler);
                minimapDiv.addEventListener('click', boundRepositionHandler);
                container.addEventListener('scroll', () =>
                    handleMinimapContanerScroll(container as HTMLDivElement, minimapDiv)
                );
            }

            return () => {
                let minimapDiv = minimap as HTMLDivElement;
                minimapDiv.removeEventListener('mousedown', boundClickHandler);
                minimapDiv.removeEventListener('click', boundRepositionHandler);
                container.removeEventListener('scroll', () =>
                    handleMinimapContanerScroll(container as HTMLDivElement, minimapDiv)
                );
                targetWindow.removeEventListener('resize', handleResizeListener, false);
            };
        }
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        handleMinimapContanerScroll,
        handleResize,
        hasBottomContainer,
        hasLeftContainer,
        minimapId,
        modelIsLoading,
        sessionId
    ]);

    React.useEffect(() => {
        let minimapContainerChild = targetWindow.document.getElementById(minimapId);
        if (minimapContainerChild && documentVisualStore.filteredPreviews.length === documentVisualStore.totalPages) {
            let container = minimapContainerChild.parentElement!.parentElement!;
            let minimap = container.children[0];

            if (typeof minimap.scrollBy !== 'function') {
                return;
            }

            // Scroll to trigger minimap refresh and show loaded previews
            minimap.scrollBy(0, 1);
            minimap.scrollBy(0, -1);
        }
    }, [documentVisualStore.filteredPreviews.length, documentVisualStore.totalPages, minimapId, targetWindow]);

    React.useEffect(() => {
        if ((projectsStore.selectedPackageId || packageId) && !modelIsLoading) {
            documentVisualStore.getPreviews(
                packageId ? packageId : projectsStore.selectedPackageId!,
                ImagePreviewSize.Small,
                projectsStore.getPackageIndexDate(packageId ? packageId : projectsStore.selectedPackageId!)
            );
        }
    }, [projectsStore.selectedPackageId, packageId, modelIsLoading, projectsStore, documentVisualStore]);

    React.useEffect(() => {
        if (documentVisualStore.highlightedInputId && sessionId && documentVisualStore.shouldScrollBlockIntoView) {
            const highlightedBlock = targetWindow.document.getElementById(`${sessionId}-${packageId}-highlight`);
            if (highlightedBlock) {
                highlightedBlock.scrollIntoView({ block: 'center' });
            }
        }
    }, [
        documentVisualStore.highlightedInputId,
        documentVisualStore.shouldScrollBlockIntoView,
        documentVisualStore.highlightedBlockProps,
        packageId,
        sessionId,
        targetWindow
    ]);

    React.useEffect(() => {
        updatePage();
        const newCalcSize = sumOfPageSideMargins * (2 - documentVisualStore.scale);
        setPageSize(`calc(100% - ${newCalcSize}px)`);
    }, [documentVisualStore.scale, updatePage]);

    React.useEffect(() => {
        calcPageWidth();
    }, [calcPageWidth, pageSize, showMinimap]);

    // const minimapHeight = documentVisualStore.calculateMinimapHeight(); // documentVisualStore.totalPages * 210;
    const DocumentLoadingSpinner = (
        <div className="create-contract-document-loading">
            <Spin size="large" />
        </div>
    );

    if (!pageSize) {
        return null;
    }

    return (
        <Minimap
            className={`alpha-document-minimap ${!showMinimap ? 'hidden' : ''}`}
            selector=".pdf-page-container"
            width={minimapWidth}
            height={minimapHeight}
            keepAspectRatio
            childComponent={renderChild}
        >
            {(projectsStore.selectedPackageId || packageId) && !modelIsLoading ? (
                <div id={minimapId}>
                    <div className="alpha-doc-content" style={{ width: pageSize }}>
                        <PdfImageViewer
                            packageId={packageId!}
                            packageIndexDate={projectsStore.getPackageIndexDate(packageId!)}
                            documentStore={documentVisualStore}
                            sessionId={sessionId}
                            hasLeftContainer={hasLeftContainer}
                            highlightBlockCallback={highlightBlockCallback}
                            minimapId={minimapId}
                            defaultScale={defaultScale}
                            targetWindow={targetWindow}
                            fieldSearchMode={fieldSearchMode}
                        />
                    </div>
                    {documentVisualStore.isRendered ? (
                        <DocumentPagination
                            documentVisualStore={documentVisualStore}
                            paginationPosition={paginationPosition!}
                            hasLeftContainer={hasLeftContainer}
                            usePageNumberInput={usePageNumberInput}
                            packageId={packageId}
                            sessionId={sessionId}
                            defaultScale={defaultScale}
                            minScale={minScale}
                            maxScale={maxScale}
                            targetWindow={targetWindow}
                        />
                    ) : (
                        DocumentLoadingSpinner
                    )}
                </div>
            ) : modelIsLoading ? (
                DocumentLoadingSpinner
            ) : (
                <></>
            )}
        </Minimap>
    );
};

export default observer(DocumentWithMinimap);

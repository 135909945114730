/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const PlusIcon: React.FC<IconProps> = ({ width = 18, height = 18, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.82812 14.209C10.1152 14.209 10.3555 13.9805 10.3555 13.6992V9.80273H14.1406C14.4219 9.80273 14.6621 9.5625 14.6621 9.27539C14.6621 8.98828 14.4219 8.75391 14.1406 8.75391H10.3555V4.85156C10.3555 4.57031 10.1152 4.3418 9.82812 4.3418C9.54102 4.3418 9.30664 4.57031 9.30664 4.85156V8.75391H5.51562C5.23438 8.75391 4.99414 8.98828 4.99414 9.27539C4.99414 9.5625 5.23438 9.80273 5.51562 9.80273H9.30664V13.6992C9.30664 13.9805 9.54102 14.209 9.82812 14.209Z"
            fill={color}
        />
    </svg>
);

export default PlusIcon;

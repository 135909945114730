import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { findLast } from 'lodash';
import { InputBaseModel } from '../../models';
import { InputRenderer, AddLimitButton } from '../../components';
import {
    CoverInput,
    ExcessInput,
    LiabilityInput,
    MainLimitInput,
    TotalSumInsuredInput,
    LimitTypeInput,
    LimitValueInput
} from '../../tab_inputs/LimitsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { TabProps } from '../../componentTypes';
import { Utils, InputIds } from '../../misc';
import { useTabForm } from '../../hooks';
import { ActionParams } from '../../types';

const addLimitButtonKey = 'add-limit-button';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.Limits.Cover]: CoverInput,
    [InputIds.Limits.Excess]: ExcessInput,
    [InputIds.Limits.Liability]: LiabilityInput,
    [InputIds.Limits.MainLimit]: MainLimitInput,
    [InputIds.Limits.TotalSumInsured]: TotalSumInsuredInput,
    [InputIds.Limits.LimitType]: LimitTypeInput,
    [InputIds.Limits.LimitValue]: LimitValueInput
} as const;

const isLimitInput = (input: InputBaseModel) => {
    return input.id.startsWith(InputIds.Limits.LimitType) || input.id.startsWith(InputIds.Limits.LimitValue);
};

const LimitsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const getComponentRows = React.useCallback((inputs: InputBaseModel[], actionParams: ActionParams) => {
        const { basicInputs, limitInputs } = inputs.reduce<{
            basicInputs: InputBaseModel[];
            limitInputs: InputBaseModel[];
        }>(
            (acc, input) => {
                if (isLimitInput(input)) {
                    acc.limitInputs.push(input);
                } else {
                    acc.basicInputs.push(input);
                }

                return acc;
            },
            { basicInputs: [], limitInputs: [] }
        );

        const basicInputRows = Utils.groupArray(basicInputs, 3).map(inputGroup =>
            inputGroup.map(input => (
                <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
            ))
        );

        const limitInputRows = Utils.groupArray(limitInputs, 2).map(inputGroup =>
            inputGroup.map(input => (
                <InputRenderer
                    key={input.id}
                    input={input}
                    component={input.id.startsWith(InputIds.Limits.LimitType) ? LimitTypeInput : LimitValueInput}
                />
            ))
        );

        const rows = [...basicInputRows, ...limitInputRows];

        const lastRow = findLast(rows, row => row.length < 3);

        const addLimitButton = <AddLimitButton key={addLimitButtonKey} actionParams={actionParams} />;

        if (lastRow) {
            lastRow.push(addLimitButton);
        } else {
            rows.push([addLimitButton]);
        }

        return rows;
    }, []);

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(LimitsTabRenderer);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomDivider } from '../../../custom_shared/components';
import { useAppStore } from '../../stores';
import DocumentViewer from './DocumentViewer';
import SideDocumentPreviewHeader from './SideDocumentPreviewHeader';
import './SideDocumentPreview.less';

const SideDocumentPreview: React.FC = () => {
    const { documentPreviewStore } = useAppStore();

    return (
        <div className="contract-ingestion-side-document-preview">
            <SideDocumentPreviewHeader />
            <CustomDivider type="horizontal" />

            <div
                className={`contract-ingestion-document-preview-container ${!documentPreviewStore.showMinimap ? 'minimap-hidden' : ''}`}
            >
                <DocumentViewer
                    defaultScale={2}
                    minScale={1.1}
                    minimapWidth={68}
                    showMinimap={documentPreviewStore.showMinimap}
                />
            </div>
        </div>
    );
};

export default observer(SideDocumentPreview);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TabBaseModel } from '../../models';
import { ExclamationCircleIcon } from '../../../custom_shared/icons';

interface Props {
    tab: TabBaseModel;
}

const TabTitle: React.FC<Props> = ({ tab }) => {
    return (
        <div className="tab-title">
            <div className="icon-container">{tab.hasError ? <ExclamationCircleIcon /> : null}</div>
            <span className="tab-name">{tab.name}</span>
        </div>
    );
};

export default observer(TabTitle);

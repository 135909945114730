/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const ThreeDotsIcon: React.FC<IconProps> = ({ width = 24, height = 24, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.54688 12.3672C7.54688 11.5 6.84375 10.8047 5.96094 10.8047C5.10156 10.8047 4.39844 11.5 4.39844 12.3672C4.39844 13.2344 5.10156 13.9297 5.96094 13.9297C6.84375 13.9297 7.54688 13.2344 7.54688 12.3672ZM13.3281 12.3672C13.3281 11.5 12.6328 10.8047 11.7656 10.8047C10.9062 10.8047 10.2109 11.5 10.2109 12.3672C10.2109 13.2344 10.9062 13.9297 11.7656 13.9297C12.6328 13.9297 13.3281 13.2344 13.3281 12.3672ZM19.1328 12.3672C19.1328 11.5 18.4375 10.8047 17.5703 10.8047C16.6875 10.8047 15.9922 11.5 15.9922 12.3672C15.9922 13.2344 16.6875 13.9297 17.5703 13.9297C18.4375 13.9297 19.1328 13.2344 19.1328 12.3672Z"
            fill={color}
        />
    </svg>
);

export default ThreeDotsIcon;

import * as React from 'react';
import { Divider } from 'antd';
import './CustomDivider.less';

interface Props {
    type: 'horizontal' | 'vertical';
}

const CustomDivider: React.FC<Props> = ({ type }: Props) => (
    <Divider className={`custom-divider ${type}`} type={type} />
);

export default CustomDivider;

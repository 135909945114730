import React from 'react';
import { Input, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import { SearchIcon, CrossIcon } from '../../../custom_shared/icons';
import { ClickableIcon } from '../../../custom_shared/components';
import { useAppStore } from '../../stores';
import './TextSearch.less';

const searchLabel = 'Search';

interface Props {
    variant: 'outlined' | 'filled';
}

const TextSearch: React.FC<Props> = ({ variant }: Props) => {
    const { documentPreviewStore } = useAppStore();

    const inputRef = React.useRef<Input>(null);

    const debouncedSearch = React.useMemo(
        () =>
            debounce(() => {
                documentPreviewStore.searchPackageFields();
            }, 500),
        [documentPreviewStore]
    );

    React.useEffect(() => {
        debouncedSearch();
    }, [documentPreviewStore.searchTerm, debouncedSearch]);

    React.useEffect(() => {
        if (documentPreviewStore.searchInputVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [documentPreviewStore.searchInputVisible]);

    if (!documentPreviewStore.searchInputVisible) {
        return (
            <Tooltip title={searchLabel}>
                <ClickableIcon
                    width={24}
                    height={24}
                    icon={SearchIcon}
                    onClick={() => documentPreviewStore.setSearchInputVisible(true)}
                />
            </Tooltip>
        );
    }

    return (
        <div className={`contract-ingestion-text-search-container ${variant}`} onClick={e => e.stopPropagation()}>
            <SearchIcon />
            <Input
                ref={inputRef}
                placeholder={searchLabel}
                value={documentPreviewStore.searchTerm}
                onChange={e => documentPreviewStore.setSearchTerm(e.target.value)}
            />
            <CrossIcon onClick={() => documentPreviewStore.setSearchInputVisible(false)} />
        </div>
    );
};

export default observer(TextSearch);

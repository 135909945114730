import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TabProps } from '../../componentTypes';
import { TabRendererRegistry } from '..';
import { useAppStore } from '../../stores';

const TabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { tabStore } = useAppStore();

    const Renderer = React.useMemo(() => TabRendererRegistry[tab.id], [tab.id]);

    if (!Renderer) {
        console.error(`Tab '${tab.id}' is not registered`);
        return null;
    }

    const getClassName = () => {
        let className = 'tab-renderer-container';

        if (tabStore.activeTabId === tab.id) {
            className += ' active';
        } else {
            className += ' hidden';
        }

        if (tabStore.showLoader) {
            className += ' loading';
        }

        return className;
    };

    return (
        <div key={tab.id} className={getClassName()}>
            <Renderer tab={tab} addFormRef={addFormRef} />
        </div>
    );
};

export default observer(TabRenderer);

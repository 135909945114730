import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomCheckboxWrapper, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';
import { useAppStore } from '../../stores';
import { InputIds } from '../../misc';

const config = {
    label: 'Create program'
};

const CreateProgramInput: InputWithoutSource = ({ input }) => {
    const { tabStore } = useAppStore();

    const businessIdInput = React.useMemo(
        () => tabStore.generalInformationTab?.inputs.find(i => i.id === InputIds.GeneralInformation.BusinessId),
        [tabStore.generalInformationTab?.inputs]
    );

    React.useEffect(() => {
        const hasBusinessId = businessIdInput?.hasValue ?? false;
        input.setValue(!hasBusinessId);
    }, [input, businessIdInput?.hasValue]);

    return (
        <CustomFormItem name={input.guid} valuePropName="checked">
            <CustomCheckboxWrapper label={config.label} input={input} disabled />
        </CustomFormItem>
    );
};

CreateProgramInput.meta = {
    inputType: InputModel.name
};

export default observer(CreateProgramInput);

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import PackageNameLabel from './PackageNameLabel';
import TextSearch from './TextSearch';
import DocumentViewer from './DocumentViewer';
import './FullScreenDocumentPreview.less';

interface Props {
    externalWindow?: Window;
}

const FullScreenDocumentPreview: React.FC<Props> = ({ externalWindow }: Props) => {
    const { sessionStore, documentPreviewStore } = useAppStore();

    if (!externalWindow) {
        console.error('External window is not provided');
        documentPreviewStore.setPreviewPosition('rightSide');
        return null;
    }

    return (
        // Yes, this is not a tab, calcPageWidth in DocumentWithMinimap.tsx requires it to be
        <div id={`tab-content-${sessionStore.sessionId}`} className="contract-ingestion-full-screen-document-preview">
            <div className="contract-ingestion-document-preview-header">
                <div className="left-container">
                    <PackageNameLabel />
                </div>
                <div className="right-container">
                    <TextSearch variant="filled" />
                </div>
            </div>

            <div className="contract-ingestion-document-preview-container">
                <DocumentViewer
                    defaultScale={0.5}
                    minScale={-1.3}
                    newPageNumberOffset={40}
                    externalWindow={externalWindow}
                    showMinimap
                />
            </div>
        </div>
    );
};

export default observer(FullScreenDocumentPreview);

import { observable, action, computed, makeObservable, reaction } from 'mobx';
import { PackageFieldResult } from '../../common/services/types';
import { ProjectsService } from '../../common/services';
import { DocumentVisualStore } from '../../documents/stores';
import { Disposer, DocumentHelper } from '../../custom_shared/misc';
import AppStore from './AppStore';
import InputStore from './InputStore';
import DocumentFieldFinder from '../misc/DocumentFieldFinder';

type PreviewPosition = 'rightSide' | 'fullScreen';

export default class DocumentPreviewStore extends Disposer {
    expanded: boolean = true;

    showMinimap: boolean = true;

    previewPosition: PreviewPosition = 'rightSide';

    searchTerm: string = '';

    searchInputVisible: boolean = false;

    fieldSearchResults: PackageFieldResult[] = [];

    constructor(
        private readonly appStore: AppStore,
        private readonly inputStore: InputStore,
        private readonly documentVisualStore: DocumentVisualStore,
        private readonly projectsService: ProjectsService
    ) {
        super();

        makeObservable(this, {
            expanded: observable,
            showMinimap: observable,
            previewPosition: observable,
            searchTerm: observable,
            searchInputVisible: observable,
            fieldSearchResults: observable,
            setExpanded: action.bound,
            setShowMinimap: action.bound,
            setPreviewPosition: action.bound,
            setSearchTerm: action.bound,
            setSearchInputVisible: action.bound,
            setFieldSearchResults: action.bound,
            fullScreenMode: computed,
            pageFilter: computed,
            visibleBlocks: computed,
            documentBlocks: computed,
            fieldSearchBlocks: computed,
            fieldSearchBlockPages: computed
        });

        this.reactions.push(
            reaction(
                () => this.searchInputVisible,
                searchInputVisible => {
                    this.documentVisualStore.highlightBlock(undefined, undefined);

                    if (!searchInputVisible) {
                        this.setSearchTerm('');
                        this.setFieldSearchResults([]);
                    }
                }
            )
        );

        this.reactions.push(
            reaction(
                () => this.visibleBlocks,
                visibleBlocks => {
                    this.documentVisualStore.setDocumentBlocks(visibleBlocks);

                    if (this.searchInputVisible && visibleBlocks.length) {
                        const firstField = this.visibleBlocks[0];
                        this.documentVisualStore.scrollAndHighlightBlock(firstField.id);
                    }
                }
            )
        );

        this.reactions.push(
            reaction(
                () => this.pageFilter,
                pageFilter => this.documentVisualStore.setPagesFilter(pageFilter)
            )
        );
    }

    setExpanded(expanded: boolean) {
        this.expanded = expanded;
    }

    setShowMinimap(showMinimap: boolean) {
        this.showMinimap = showMinimap;
    }

    setPreviewPosition(previewPosition: PreviewPosition) {
        this.previewPosition = previewPosition;
    }

    setSearchTerm(searchTerm: string) {
        this.searchTerm = searchTerm;
    }

    setSearchInputVisible(searchInputVisible: boolean) {
        this.searchInputVisible = searchInputVisible;
    }

    setFieldSearchResults(fieldSearchResults: PackageFieldResult[]) {
        this.fieldSearchResults = fieldSearchResults;
    }

    async searchPackageFields() {
        const searchTerm = this.searchTerm.trim();

        if (searchTerm.length < 3) {
            return this.setFieldSearchResults([]);
        }

        const linesBlocks = await this.projectsService.getPackageLines({
            projectId: this.appStore.projectId,
            packageId: this.appStore.packageId,
            searchTerm,
            pageSize: 999999,
            page: 0,
            fieldsSearch: {
                before: { blockType: 'HORIZONTAL_MULTILINE_BLOCK' }
            },
            searchOptions: {
                childFieldFilter: {
                    blockType: 'WORD_BLOCK'
                },
                textSearch: {
                    ignoreNewLines: true
                }
            }
        });

        if (!linesBlocks || !linesBlocks.result.length) {
            return this.setFieldSearchResults([]);
        }

        this.setFieldSearchResults(DocumentFieldFinder.searchChildFields(searchTerm, linesBlocks.result));
    }

    get fullScreenMode() {
        return this.previewPosition === 'fullScreen';
    }

    get pageFilter() {
        return this.searchInputVisible ? this.fieldSearchBlockPages : [];
    }

    get visibleBlocks() {
        return this.searchInputVisible ? this.fieldSearchBlocks : this.documentBlocks;
    }

    get documentBlocks() {
        if (!this.documentVisualStore.pageWidth) {
            return [];
        }

        return DocumentHelper.prepareDocumentBlocks(
            this.inputStore.allInputs,
            this.documentVisualStore.pageWidth,
            this.appStore.packageId,
            'guid'
        );
    }

    get fieldSearchBlocks() {
        if (!this.documentVisualStore.pageWidth) {
            return [];
        }

        return DocumentHelper.preparePackageFieldBlocks(
            this.fieldSearchResults,
            this.documentVisualStore.pageWidth,
            this.appStore.packageId
        );
    }

    get fieldSearchBlockPages() {
        return [...new Set(this.fieldSearchResults.map(block => block.page))];
    }

    dispose() {
        this.disposeReactions();
    }
}

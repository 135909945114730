import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Row, Col, Form } from 'antd';
import { StyleUtils } from '../../misc';
import { BasicTabModel } from '../../models';
import { LayoutRendererProps } from '../../componentTypes';
import './BasicLayoutRenderer.less';

interface Props extends LayoutRendererProps {
    tab: BasicTabModel;
}

const BasicLayoutRenderer: React.FC<Props> = (props: Props) => {
    const { tab, formKey, form, onValuesChange, getComponentRows } = props;

    return (
        <div className="basic-layout-renderer-container custom-scroll">
            <Form id={tab.id} key={formKey} form={form} onValuesChange={onValuesChange}>
                <Collapse activeKey={tab.expanded ? [tab.id] : []} onChange={tab.toggleExpanded} bordered={false}>
                    <Collapse.Panel
                        className="tab-collapse-panel"
                        header={<span className="header-title">{tab.name}</span>}
                        key={tab.id}
                    >
                        {getComponentRows(tab.inputs, { TabId: tab.id }).map((componentRow, index) => (
                            <Row key={index} gutter={10}>
                                {componentRow.map(component => (
                                    <Col key={component.key} style={StyleUtils.getTabColumnStyle(8)}>
                                        {component}
                                    </Col>
                                ))}
                            </Row>
                        ))}
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </div>
    );
};

export default observer(BasicLayoutRenderer);

import { makeObservable, observable, action, computed, reaction } from 'mobx';
import { CustomSessionRuntimeData } from '../../custom_shared/types';
import { Disposer } from '../../custom_shared/misc';
import { TypeUtils } from '../../custom_shared/misc';
import { ActionParams } from '../types';
import AppStore from './AppStore';
import SessionStore from './SessionStore';
import SettingsStore from './SettingsStore';
import InputStore from './InputStore';
import TabStore from './TabStore';

const Actions = {
    ChangeBusinessType: 'ChangeBusinessType',
    AddLimit: 'AddLimit',
    AddDeduction: 'AddDeduction',
    RebuildTabs: 'RebuildTabs',
    SendContract: 'SendContract',
    ExportToJson: 'ExportToJson',
    ClearPostAction: 'ClearPostAction'
} as const;

const Params = {
    ActionParams: 'ActionParams',
    LayoutSettings: 'LayoutSettings'
} as const;

export const ActionParamKeys = {
    TabId: 'TabId',
    LayerId: 'LayerId',
    SectionId: 'SectionId'
} as const;

export default class ActionStore extends Disposer {
    downloadUrl: string | null = null;

    constructor(
        private readonly appStore: AppStore,
        private readonly tabStore: TabStore,
        private readonly inputStore: InputStore,
        private readonly settingStore: SettingsStore,
        private readonly sessionStore: SessionStore
    ) {
        super();

        makeObservable(this, {
            downloadUrl: observable,
            setDownloadUrl: action,
            actionsDisabled: computed
        });

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                () => {
                    if (!this.sessionStore.response || this.sessionStore.inProgress) {
                        return;
                    }

                    const postAction = this.sessionStore.response.postAction;
                    const downloadAction = postAction && postAction.type === 'Download' ? postAction : null;

                    if (!downloadAction) {
                        return this.setDownloadUrl(null);
                    }

                    const downloadUrl = TypeUtils.isString(downloadAction.metadata.url)
                        ? downloadAction.metadata.url
                        : null;

                    this.setDownloadUrl(downloadUrl);
                }
            )
        );

        this.reactions.push(
            reaction(
                () => this.downloadUrl,
                () => {
                    if (!this.downloadUrl) {
                        return;
                    }

                    const downloadMessage = `Do you want to download file produced after executing '${this.appStore.applicationData.appName}' on '${this.appStore.packageName}'?`;

                    if (confirm(downloadMessage)) {
                        this.sessionStore.getFileFromIotaTempStorage(this.downloadUrl);
                        this.clearPostAction();
                    } else {
                        this.clearPostAction();
                    }
                }
            )
        );
    }

    setDownloadUrl(downloadUrl: string | null) {
        this.downloadUrl = downloadUrl;
    }

    get actionsDisabled() {
        return (
            this.sessionStore.isInitializing ||
            this.sessionStore.isLoading ||
            this.sessionStore.inProgress ||
            this.sessionStore.contractSending ||
            this.sessionStore.contractSent ||
            this.inputStore.inputsLoading
        );
    }

    async changeBusinessType() {
        await this.sessionStore.continueSession(Actions.ChangeBusinessType, this.getRuntimeData());
    }

    async addLimit(actionParams: ActionParams) {
        await this.sessionStore.continueSession(
            Actions.AddLimit,
            this.getRuntimeData({ [Params.ActionParams]: actionParams })
        );
    }

    async addDeduction(actionParams: ActionParams) {
        await this.sessionStore.continueSession(
            Actions.AddDeduction,
            this.getRuntimeData({ [Params.ActionParams]: actionParams })
        );
    }

    async rebuildTabs() {
        await this.sessionStore.continueSession(Actions.RebuildTabs, this.getRuntimeData());
    }

    async sendContract() {
        this.sessionStore.setContractStatus('ContractSending');
        await this.sessionStore.continueSession(Actions.SendContract, this.getRuntimeData());
    }

    async exportToJson() {
        await this.sessionStore.continueSession(Actions.ExportToJson, this.getRuntimeData());
    }

    async clearPostAction() {
        await this.sessionStore.continueSession(Actions.ClearPostAction, this.getRuntimeData());
    }

    dispose() {
        this.disposeReactions();
    }

    private getRuntimeData(actionParams: Record<string, unknown> = {}): CustomSessionRuntimeData {
        const tabsRuntimeData = this.tabStore.tabs.reduce<CustomSessionRuntimeData>((acc, tab) => {
            acc[tab.id] = { value: JSON.stringify(tab.dto) };
            return acc;
        }, {});

        const additionalRuntimeData = Object.entries(actionParams).reduce<CustomSessionRuntimeData>(
            (acc, [key, value]) => {
                acc[key] = { value };
                return acc;
            },
            {}
        );

        const settings = {
            [Params.LayoutSettings]: {
                value: this.settingStore.layoutSettings ? JSON.stringify(this.settingStore.layoutSettings.dto) : null
            }
        };

        return {
            ...tabsRuntimeData,
            ...additionalRuntimeData,
            ...settings
        };
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import { InputRenderer, GeneralInformationSubsection, PanelSwitch } from '../../components';
import {
    TitleInput,
    CreateProgramInput,
    MainClassInput,
    ClassInput,
    SubClassInput,
    TypeOfParticipationInput,
    BusinessTypeInput,
    UnderwritingYearInput,
    PeriodStartInput,
    PeriodEndInput,
    CountryGroupsInput,
    CountriesInput,
    LifecycleInput,
    CurrencyInput,
    CedentInput,
    InsuredInput,
    BrokerInput,
    ReinsurerInput,
    ReportUnitsInput,
    BusinessIdInput,
    ExpiringPeriodStartInput
} from '../../tab_inputs/GeneralInformationTabInputs';
import { TabProps } from '../../componentTypes';
import { InstanceUtils, StyleUtils, InputIds } from '../../misc';
import { useTabForm } from '../../hooks';
import './GeneralInformationTabRenderer.less';

const Subsections = {
    contractRenewal: {
        key: 'contractRenewal',
        label: 'Contract Renewal'
    },
    contractStructure: {
        key: 'contractStructure',
        label: 'Contract Structure'
    },
    period: {
        key: 'period',
        label: 'Period'
    },
    coverageClassification: {
        key: 'coverageClassification',
        label: 'Coverage Classification'
    },
    partners: {
        key: 'partners',
        label: 'Partners'
    }
} as const;

const GeneralInformationTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const subsectionRefs = React.useRef<Map<string, React.RefObject<HTMLDivElement>>>(new Map());

    const addSubsectionRef = React.useCallback(
        (subsectionKey: string, subsectionRef: React.RefObject<HTMLDivElement>) => {
            subsectionRefs.current.set(subsectionKey, subsectionRef);
        },
        []
    );

    const getInputById = React.useCallback((id: string) => tab.inputs.find(input => input.id === id), [tab.inputs]);

    const inputs = React.useMemo(
        () => ({
            titleInput: getInputById(InputIds.GeneralInformation.Title),
            createProgramInput: getInputById(InputIds.GeneralInformation.CreateProgram),
            mainClassInput: getInputById(InputIds.GeneralInformation.MainClass),
            classInput: getInputById(InputIds.GeneralInformation.Class),
            subClassInput: getInputById(InputIds.GeneralInformation.SubClass),
            businessTypeInput: getInputById(InputIds.GeneralInformation.BusinessType),
            typeOfParticipationInput: getInputById(InputIds.GeneralInformation.TypeOfParticipation),
            underwritingYearInput: getInputById(InputIds.GeneralInformation.UnderwritingYear),
            periodStartInput: getInputById(InputIds.GeneralInformation.PeriodStart),
            periodEndInput: getInputById(InputIds.GeneralInformation.PeriodEnd),
            countryGroupsInput: getInputById(InputIds.GeneralInformation.CountryGroups),
            countriesInput: getInputById(InputIds.GeneralInformation.Countries),
            lifecycleInput: getInputById(InputIds.GeneralInformation.Lifecycle),
            currencyInput: getInputById(InputIds.GeneralInformation.Currency),
            insuredInput: getInputById(InputIds.GeneralInformation.Insured),
            reinsurerInput: getInputById(InputIds.GeneralInformation.Reinsurer),
            reportUnitsInput: getInputById(InputIds.GeneralInformation.ReportUnits),
            cedentInput: getInputById(InputIds.GeneralInformation.Cedent),
            brokerInput: getInputById(InputIds.GeneralInformation.Broker),
            businessIdInput: getInputById(InputIds.GeneralInformation.BusinessId),
            expiringPeriodStartInput: getInputById(InputIds.GeneralInformation.ExpiringPeriodStart)
        }),
        [getInputById]
    );

    const hasClassification = React.useMemo(
        () => !!(inputs.mainClassInput || inputs.classInput || inputs.subClassInput),
        [inputs.classInput, inputs.mainClassInput, inputs.subClassInput]
    );

    const hasCountries = React.useMemo(
        () => !!(inputs.countriesInput || inputs.countryGroupsInput),
        [inputs.countriesInput, inputs.countryGroupsInput]
    );

    const renderCoverageClassification = React.useMemo(
        () => hasClassification || hasCountries,
        [hasClassification, hasCountries]
    );

    const panelSwitchOptions = React.useMemo(
        () =>
            Object.values(Subsections)
                .filter(subsection => {
                    switch (subsection.key) {
                        case 'coverageClassification':
                            return renderCoverageClassification;
                        default:
                            return true;
                    }
                })
                .map(subsection => ({
                    id: subsection.key,
                    label: subsection.label
                })),
        [renderCoverageClassification]
    );

    const onPanelSwitchClick = (subsectionKey: string) => {
        const subsectionRef = subsectionRefs.current.get(subsectionKey);

        if (subsectionRef && subsectionRef.current) {
            subsectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    if (!InstanceUtils.isBasicTab(tab)) {
        return null;
    }

    return (
        <>
            <PanelSwitch options={panelSwitchOptions} onClick={onPanelSwitchClick} />

            <div className="general-information-tab-renderer custom-scroll">
                <Form id={tab.id} key={formKey} form={form} onValuesChange={onValuesChange}>
                    <div className="subsections-container">
                        <GeneralInformationSubsection
                            subsectionKey={Subsections.contractRenewal.key}
                            subsectionName={Subsections.contractRenewal.label}
                            addSubsectionRef={addSubsectionRef}
                        >
                            <Row gutter={10}>
                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.businessIdInput} component={BusinessIdInput} />
                                </Col>

                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer
                                        input={inputs.expiringPeriodStartInput}
                                        component={ExpiringPeriodStartInput}
                                    />
                                </Col>
                            </Row>
                        </GeneralInformationSubsection>

                        <GeneralInformationSubsection
                            subsectionKey={Subsections.contractStructure.key}
                            subsectionName={Subsections.contractStructure.label}
                            addSubsectionRef={addSubsectionRef}
                        >
                            <Row gutter={10}>
                                <Col style={StyleUtils.getTabColumnStyle(16)}>
                                    <InputRenderer input={inputs.titleInput} component={TitleInput} />
                                </Col>

                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.createProgramInput} component={CreateProgramInput} />
                                </Col>
                            </Row>

                            <Row gutter={10}>
                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.businessTypeInput} component={BusinessTypeInput} />
                                </Col>

                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer
                                        input={inputs.typeOfParticipationInput}
                                        component={TypeOfParticipationInput}
                                    />
                                </Col>

                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.lifecycleInput} component={LifecycleInput} />
                                </Col>
                            </Row>

                            <Row gutter={10}>
                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.currencyInput} component={CurrencyInput} />
                                </Col>

                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.reportUnitsInput} component={ReportUnitsInput} />
                                </Col>
                            </Row>
                        </GeneralInformationSubsection>

                        <GeneralInformationSubsection
                            subsectionKey={Subsections.period.key}
                            subsectionName={Subsections.period.label}
                            addSubsectionRef={addSubsectionRef}
                        >
                            <Row gutter={10}>
                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer
                                        input={inputs.underwritingYearInput}
                                        component={UnderwritingYearInput}
                                    />
                                </Col>

                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.periodStartInput} component={PeriodStartInput} />
                                </Col>

                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.periodEndInput} component={PeriodEndInput} />
                                </Col>
                            </Row>
                        </GeneralInformationSubsection>

                        {renderCoverageClassification && (
                            <GeneralInformationSubsection
                                subsectionKey={Subsections.coverageClassification.key}
                                subsectionName={Subsections.coverageClassification.label}
                                addSubsectionRef={addSubsectionRef}
                            >
                                {hasClassification && (
                                    <>
                                        <Row gutter={10}>
                                            <Col style={StyleUtils.getTabColumnStyle(16)}>
                                                <InputRenderer
                                                    input={inputs.mainClassInput}
                                                    component={MainClassInput}
                                                />
                                            </Col>
                                        </Row>

                                        <Row gutter={10}>
                                            <Col style={StyleUtils.getTabColumnStyle(24)}>
                                                <InputRenderer input={inputs.classInput} component={ClassInput} />
                                            </Col>
                                        </Row>

                                        <Row gutter={10}>
                                            <Col style={StyleUtils.getTabColumnStyle(16)}>
                                                <InputRenderer input={inputs.subClassInput} component={SubClassInput} />
                                            </Col>

                                            {inputs.countryGroupsInput && (
                                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                                    <InputRenderer
                                                        input={inputs.countryGroupsInput}
                                                        component={CountryGroupsInput}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                )}

                                {inputs.countriesInput && (
                                    <Row gutter={10}>
                                        <Col style={StyleUtils.getTabColumnStyle(24)}>
                                            <InputRenderer input={inputs.countriesInput} component={CountriesInput} />
                                        </Col>
                                    </Row>
                                )}
                            </GeneralInformationSubsection>
                        )}

                        <GeneralInformationSubsection
                            subsectionKey={Subsections.partners.key}
                            subsectionName={Subsections.partners.label}
                            addSubsectionRef={addSubsectionRef}
                        >
                            <Row gutter={[10, 10]} wrap>
                                {inputs.cedentInput && (
                                    <Col style={StyleUtils.getTabColumnStyle(8)}>
                                        <InputRenderer input={inputs.cedentInput} component={CedentInput} />
                                    </Col>
                                )}

                                {inputs.brokerInput && (
                                    <Col style={StyleUtils.getTabColumnStyle(8)}>
                                        <InputRenderer input={inputs.brokerInput} component={BrokerInput} />
                                    </Col>
                                )}

                                {inputs.insuredInput && (
                                    <Col style={StyleUtils.getTabColumnStyle(8)}>
                                        <InputRenderer input={inputs.insuredInput} component={InsuredInput} />
                                    </Col>
                                )}

                                <Col style={StyleUtils.getTabColumnStyle(8)}>
                                    <InputRenderer input={inputs.reinsurerInput} component={ReinsurerInput} />
                                </Col>
                            </Row>
                        </GeneralInformationSubsection>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default observer(GeneralInformationTabRenderer);

import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { first } from 'lodash';
import { Disposer } from '../../custom_shared/misc';
import { TabBaseModel } from '../models';
import { TabFactory, TabIds } from '../misc';
import { TabBaseDto } from '../types';
import SessionStore from './SessionStore';
import SourceStore from './SourceStore';

export default class TabStore extends Disposer {
    activeTabId: string = '';

    tabs: TabBaseModel[] = [];

    constructor(
        private readonly sessionStore: SessionStore,
        private readonly sourceStore: SourceStore
    ) {
        super();

        makeObservable(this, {
            activeTabId: observable,
            tabs: observable,
            showLoader: computed,
            generalInformationTab: computed,
            createTabs: action,
            updateTabs: action,
            setActiveTabId: action.bound
        });

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                () => {
                    if (this.sessionStore.inProgress) {
                        return;
                    }

                    const data = this.sessionStore.response?.tabs ?? [];

                    if (!this.tabs.length) {
                        this.createTabs(data);
                    } else {
                        this.updateTabs(data);
                    }
                }
            )
        );

        this.reactions.push(
            reaction(
                () => this.tabs,
                () => {
                    if (this.activeTabId && !this.tabs.some(t => t.id === this.activeTabId)) {
                        this.setActiveTabId('');
                    }

                    if (!this.activeTabId && this.firstTab) {
                        this.setActiveTabId(this.firstTab.id);
                    }
                }
            )
        );
    }

    get showLoader() {
        if (this.sessionStore.contractSending) {
            return false;
        }

        return this.sessionStore.isLoading || this.sessionStore.inProgress;
    }

    get firstTab() {
        return first(this.tabs);
    }

    get generalInformationTab() {
        return this.tabs.find(tab => tab.id === TabIds.GeneralInformation);
    }

    setActiveTabId(activeTabId: string) {
        this.activeTabId = activeTabId;
    }

    createTabs(data: TabBaseDto[]) {
        this.tabs = data.map(tab => TabFactory.createTab(tab, this.sourceStore.sources));
    }

    updateTabs(data: TabBaseDto[]) {
        data.forEach(tab => {
            const existingTab = this.tabs.find(t => t.id === tab.id);

            if (existingTab) {
                existingTab.update(tab, this.sourceStore.sources);
            } else {
                this.tabs.push(TabFactory.createTab(tab, this.sourceStore.sources));
            }
        });

        this.tabs = this.tabs.filter(tab => data.some(t => t.id === tab.id));
    }

    getTabByInputGuid(inputGuid: string) {
        return this.tabs.find(tab => tab.inputs.some(input => input.guid === inputGuid));
    }

    dispose() {
        this.disposeReactions();
    }
}

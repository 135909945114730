type SetItemError = { quotaExceeded?: boolean };

/* eslint-disable no-dupe-class-members */
export default class LocalStorageService {
    setItem<T>(key: string, value: T): void | SetItemError {
        try {
            return localStorage.setItem(key, JSON.stringify({ value }));
        } catch (e) {
            if (e instanceof DOMException && e.name === 'QuotaExceededError') {
                console.error('Storage limit exceeded: ', e);
                return { quotaExceeded: true };
            }

            console.error('Error storing data:', e);
        }
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    getItem<T>(key: string): T | null;
    getItem<T>(key: string, otherwise: T): T;
    getItem<T>(key: string, otherwise?: T): T | null {
        const data: string | null = localStorage.getItem(key);

        if (data !== null) {
            return JSON.parse(data).value;
        }

        if (otherwise !== undefined) {
            return otherwise;
        }

        return null;
    }

    getItemKeysBySuffix(suffix: string) {
        return Array.from({ length: localStorage.length }, (_, i) => localStorage.key(i)).filter(
            (key): key is string => !!(key && key.endsWith(suffix))
        );
    }
}

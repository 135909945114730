import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd';
import { useAppStore } from '../../stores';
import { ReloadButton, SettingsModal, MoreActionsMenu, SendContractButton } from '..';
import './AppHeader.less';
import ContractStatus from './ContractStatus';

interface Props {
    formRefs: React.MutableRefObject<Map<string, FormInstance<unknown>>>;
}

const AppHeader: React.FC<Props> = ({ formRefs }) => {
    const { tab, sessionStore } = useAppStore();

    return (
        <div className="contract-ingestion-app-header">
            <div className="left-container">
                <span className="application-name">{tab.metadata.applicationData.appName as string}</span>
                <span className="package-name">{tab.metadata.packageName as string}</span>
            </div>
            <div className="center-container">
                <ContractStatus />
            </div>

            {!sessionStore.contractSent && (
                <div className="right-container">
                    <ReloadButton />
                    <SettingsModal />
                    <MoreActionsMenu />
                    <SendContractButton formRefs={formRefs} />
                </div>
            )}
        </div>
    );
};

export default observer(AppHeader);

const TabIds = {
    GeneralInformation: 'GeneralInformation',
    Classifications: 'Classifications',
    Countries: 'Countries',
    Limits: 'Limits',
    Premiums: 'Premiums',
    Deductions: 'Deductions',
    Share: 'Share',
    Claims: 'Claims',
    PortfolioConditions: 'PortfolioConditions',
    AdminConditions: 'AdminConditions',
    OtherConditions: 'OtherConditions',
    Instalments: 'Instalments',
    Adjustments: 'Adjustments',
    Reinstatements: 'Reinstatements'
} as const;

export default TabIds;

import { computed } from 'mobx';
import { LocalStorageService } from '../../common/services';
import { QuestionDto } from '../types';
import { AskAlphaVisualStore } from '../stores';
import { AskAlphaQuestionModel } from '../models';
import { Utils } from '../../common/misc/Utils';

interface QuestionHistoryItem {
    packageId: string;
    questions: QuestionDto[];
}

export default class AskAlphaHistoryService {
    private readonly service: LocalStorageService;

    @computed
    get packageQuestionsKey() {
        if (!this.store.settings.values.projectId) {
            return null;
        }

        return `${this.store.settings.values.projectId}${this.packageQuestionsKeySuffix}`;
    }

    private readonly packageQuestionsKeySuffix = '_ask-aplha-question-history';

    private readonly lastSentMessageKey = 'ask-aplha-last-sent-message';

    constructor(private readonly store: AskAlphaVisualStore) {
        this.service = new LocalStorageService();
    }

    getPackageQuestions(packageId: string) {
        if (!this.packageQuestionsKey) {
            return [];
        }

        const historyItem = this.service
            .getItem<QuestionHistoryItem[]>(this.packageQuestionsKey, [])
            .find(i => i.packageId === packageId);

        if (!historyItem) {
            return [];
        }

        return historyItem.questions;
    }

    addPackageQuestion(question: AskAlphaQuestionModel) {
        if (!this.packageQuestionsKey) {
            return;
        }

        const questionHistoryItems = this.service.getItem<QuestionHistoryItem[]>(this.packageQuestionsKey, []);
        const historyItem = questionHistoryItems.find(i => i.packageId === question.packageId);

        if (historyItem) {
            historyItem.questions.push(question.dto);
        } else {
            questionHistoryItems.push({ packageId: question.packageId, questions: [question.dto] });
        }

        const quotaExceeded = this.service.setItem(this.packageQuestionsKey, questionHistoryItems);

        if (quotaExceeded) {
            this.deleteHalfQuestions();
            this.addPackageQuestion(question);
        }
    }

    removePackageQuestions(packageId: string) {
        if (!this.packageQuestionsKey) {
            return;
        }

        const questionHistoryItems = this.service
            .getItem<QuestionHistoryItem[]>(this.packageQuestionsKey, [])
            .filter(i => i.packageId !== packageId);

        this.service.setItem(this.packageQuestionsKey, questionHistoryItems);
    }

    removeAllPackagesQuestions() {
        if (!this.packageQuestionsKey) {
            return;
        }

        this.service.removeItem(this.packageQuestionsKey);
    }

    getLastSentMessage() {
        return this.service.getItem<string>(this.lastSentMessageKey, '');
    }

    updateLastSentMessage(lastSentMessage: string) {
        this.service.setItem(this.lastSentMessageKey, lastSentMessage);
    }

    deleteHalfQuestions() {
        // Get cached questions from all projects
        const allQuestions = this.service
            .getItemKeysBySuffix(this.packageQuestionsKeySuffix)
            .reduce<[string, QuestionDto][]>(
                (acc, key) =>
                    acc.concat(
                        this.service
                            .getItem<QuestionHistoryItem[]>(key, [])
                            .map(item => item.questions.map(question => [key, question] as [string, QuestionDto]))
                            .flat()
                    ),
                []
            );

        // Take the second half of the questions sorted by date
        const halfQuestions = allQuestions
            .sort((a, b) => Utils.safeDateCompare(a[1].createDate, b[1].createDate))
            .slice(Math.ceil(allQuestions.length / 2));

        // Set filtered questions back to storage
        const storageItems = halfQuestions.reduce<Record<string, QuestionHistoryItem[]>>((acc, [key, question]) => {
            if (!acc[key]) {
                acc[key] = [];
            }

            const packageQuestions = acc[key].find(i => i.packageId === question.packageId);

            if (packageQuestions) {
                packageQuestions.questions.push(question);
            } else {
                acc[key].push({ packageId: question.packageId, questions: [question] });
            }

            return acc;
        }, {});

        Object.entries(storageItems).forEach(([key, value]) => this.service.setItem(key, value));
    }
}

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../common/stores';
import { useAppStore } from '../../stores';
import DocumentWithMinimap from '../../../documents/components/DocumentWithMinimap';

interface Props {
    defaultScale?: number;
    minScale?: number;
    maxScale?: number;
    newPageNumberOffset?: number;
    minimapWidth?: number;
    showMinimap?: boolean;
    externalWindow?: Window;
}

const DocumentViewer: React.FC<Props> = (props: Props) => {
    const { defaultScale, minScale, maxScale, newPageNumberOffset, minimapWidth, showMinimap, externalWindow } = props;

    const { projectsStore } = useStore();

    const { tab, sessionStore, documentVisualStore, documentPreviewStore } = useAppStore();

    return (
        <DocumentWithMinimap
            packageId={tab.metadata.packageId}
            sessionId={sessionStore.sessionId}
            documentVisualStore={documentVisualStore}
            projectsStore={projectsStore}
            modelIsLoading={sessionStore.isLoading}
            defaultScale={defaultScale}
            minScale={minScale}
            maxScale={maxScale}
            minimapWidth={minimapWidth}
            newPageNumberOffset={newPageNumberOffset}
            targetWindow={externalWindow ?? window}
            usePageNumberInput
            showMinimap={showMinimap}
            fieldSearchMode={documentPreviewStore.searchInputVisible}
        />
    );
};

export default observer(DocumentViewer);

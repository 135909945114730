import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    ShareOfferInput,
    ShareWrittenInput,
    ShareSignedInput,
    CessionPercentInput
} from '../../tab_inputs/ShareTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils, InputIds } from '../../misc';
import { useTabForm } from '../../hooks';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.Share.ShareOffer]: ShareOfferInput,
    [InputIds.Share.ShareWritten]: ShareWrittenInput,
    [InputIds.Share.ShareSigned]: ShareSignedInput,
    [InputIds.Share.CessionPercent]: CessionPercentInput
} as const;

const ShareTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 3).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(ShareTabRenderer);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    EstimatedPremiumIncomeInput,
    MinimalPremiumInput,
    DepositPremiumInput,
    SubjectPremiumIncomeInput,
    PremiumRateInput,
    PremiumTypeInput,
    TotalGrossPremiumInput
} from '../../tab_inputs/PremiumsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils, InputIds } from '../../misc';
import { useTabForm } from '../../hooks';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.Premiums.EstimatedPremiumIncome]: EstimatedPremiumIncomeInput,
    [InputIds.Premiums.MinimalPremium]: MinimalPremiumInput,
    [InputIds.Premiums.DepositPremium]: DepositPremiumInput,
    [InputIds.Premiums.SubjectPremiumIncome]: SubjectPremiumIncomeInput,
    [InputIds.Premiums.PremiumRate]: PremiumRateInput,
    [InputIds.Premiums.PremiumType]: PremiumTypeInput,
    [InputIds.Premiums.TotalGrossPremium]: TotalGrossPremiumInput
} as const;

const PremiumsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 3).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(PremiumsTabRenderer);
